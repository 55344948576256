import { isISODate } from "../../shared/DateUtil";
import toBoolean from "../../util/toBoolean";
import { getFieldData } from "./filterDataForFieldDefinitions";
import testFieldOrTabIfEvaluatingFalse from "./testFieldOrTabIfEvaluatingFalse";

/* Validate a group field such as address or two text box */
const validateGroupField = ( field, values ) => {

    const requiredNames = 
            Object.entries( field )
            .filter( ([k, f]) => f?.required )
            .map( ([k, f]) => f?.name );
    
    const valid = requiredNames.reduce( (accumulator, currentValue) => accumulator && values[ currentValue ], true );

    const result = valid? true: false;

    return result;
}

/* validate a value against a regex which may be null. Null regex always passes */
const validateRegex = ( regex, value ) => {
    
    let result = false;
    if ( regex ){
        try{
            const regExp = new RegExp( regex );
            if( value && regExp.test( value ) ){
                result = true;
            }
        }catch(e){
            console.log( 'Bad regular expression \'' + regex + '\'' );
        }
    }else { 
        result = true;
    }

    return result;
}

/* validate a single field such as */
const validateSingleField = ( field, values, objectIndex ) => {

    const thisValue = getFieldData( values, field, objectIndex);
    let valid;
    if (toBoolean(field.required) || (thisValue && field.regex)) {
        const isCheckbox = 'checkbox' === field.controlType?.toLowerCase();
        if(isCheckbox){
            valid = thisValue;
        }else if( validateRegex( field.regex, thisValue ) ){
            valid = thisValue;
        }
    } else {
        valid = true;
    }
    
    const result = valid? true: false;
    return result;
}

const validateDateField = ( field, values, objectIndex ) => {
    const thisValue = getFieldData( values, field, objectIndex);

    const valid = isISODate(thisValue)||!toBoolean(field.required);

    return valid;
}

/* validates whether a field is currently valid (i.e the user has filled it 
in */
const validate = ( fieldDefinition, formFieldData, objectIndex = 0) => {

    let validator = ( field, values ) => true;
    
    const lcControlType = fieldDefinition.controlType?.toLowerCase()?? 'textbox';

    if( 'address' === lcControlType || 'twotextbox' === lcControlType ){
        validator = validateGroupField;
    } else if( ['datetextbox'].includes( lcControlType ) ){
        validator = validateDateField;
    }else if( ['text', 'textbox', 'dropdownlist',
            'checkbox', 'email', 'tel', 'timetextbox','radiobutton'].includes( lcControlType ) ){
        validator = validateSingleField;
    }
    
    let valid; 
    /* don't validate fields that are hidden by an if statement that evaluates 
    false */
    if (testFieldOrTabIfEvaluatingFalse(fieldDefinition, formFieldData)){
        valid = validator( fieldDefinition, formFieldData, objectIndex );
    }else{
        valid = true;
    }

    return valid;
}

export {validate};